import { styled } from '@mui/material/styles'
import moment from 'moment'
import { useAppSelector } from '../../../../store/hooks'
import _ from 'lodash'
import { Bom, BomComponent, CallOff, Product, Project } from '../../../../models/models'
import { useMemo } from 'react'
import { CallOffFilterBar } from './CallOffFilterBar'
import { DispatchedStatus, PriceCalculation } from '../../../../store/project-call-off-slice'
import { formatNumberToUkString } from '../../../../util/util'
import { Button } from 'antd'
import { toastFailure } from '../../../../util/toast'

const Table = styled('table')``

const Tr = styled('tr')`
    :not(:last-of-type) {
        border-bottom: 1px solid #eee;
    }
`

const Th = styled('th')`
    height: 120px;
    padding: 16px 10px;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    vertical-align: top;
    box-sizing: border-box;
    border: 1px solid #eee;
    background: #f8f8f8;
`

const Td = styled('td')`
    height: 56px;
    padding: 0 10px;
    text-align: left;
    font-size: 14px;
`

const TdClamp = styled('div')`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`

enum CallOffCellColors {
    Even = '#fff2cc',
    Uneven = '#d0cece',
}

enum TextColors {
    DarkBlue = '#00008B',
    Red = '#FF0000',
}

const P = styled('p')({
    color: TextColors.DarkBlue,
    marginBottom: '0',
})

enum RowType {
    StockItem = 'StockItem',
    Bom = 'Bom',
    BomComponent = 'BomComponent',
    Space = 'Space',
}

interface StockItemRow {
    type: RowType.StockItem
    stockItem: Product
}

interface BomRow {
    type: RowType.Bom
    bom: Bom
}

interface BomComponentRow {
    type: RowType.BomComponent
    bomComponent: BomComponent
    bom: Bom
}

interface SpaceRow {
    type: RowType.Space
}

type TableBodyRow = StockItemRow | BomRow | BomComponentRow | SpaceRow

const isStockItemRow = (row: TableBodyRow): row is StockItemRow => {
    return row.type === RowType.StockItem
}

const isBomRow = (row: TableBodyRow): row is BomRow => {
    return row.type === RowType.Bom
}

const isBomComponentRow = (row: TableBodyRow): row is BomComponentRow => {
    return row.type === RowType.BomComponent
}

const isSpaceRow = (row: TableBodyRow): row is SpaceRow => {
    return row.type === RowType.Space
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

export function DeliverySchedule(props: Props) {
    const { callOffs, projectStockItems, projectBuiltItems, salesOrders, filters } = useAppSelector(
        (state) => state.projectCallOffs
    )
    const project: Project | null = useAppSelector(
        (state) => state.singleProjectView.projectBeingUpdated
    )

    const filteredCallOffs = useMemo(() => {
        return (
            callOffs
                .filter((callOff) => {
                    if (filters.dispatchedStatus === DispatchedStatus.All) return true

                    if (filters.dispatchedStatus === DispatchedStatus.Dispatched) {
                        return callOff.dispatchedDate
                    }

                    if (filters.dispatchedStatus === DispatchedStatus.NotDispatched) {
                        return !callOff.dispatchedDate
                    }

                    return true
                })
                // filter sales orders
                .filter((callOff) => {
                    if (filters.excludedSalesOrders.length === 0) return true

                    return !filters.excludedSalesOrders.includes(callOff.salesOrder.documentNo)
                })
                .sort((a, b) => {
                    const aDate = a.dispatchedDate ? a.dispatchedDate : a.deliveryDate
                    const bDate = b.dispatchedDate ? b.dispatchedDate : b.deliveryDate

                    if (aDate && bDate) {
                        return moment(aDate).isBefore(moment(bDate)) ? 1 : -1
                    }

                    if (aDate) return 1
                    if (bDate) return -1

                    return 0
                })
        )
    }, [filters, callOffs])

    const rows = useMemo(() => {
        const rows: TableBodyRow[] = []

        projectBuiltItems.forEach((projectBuiltItem) => {
            rows.push({
                type: RowType.Bom,
                bom: projectBuiltItem.builtItem,
            })

            if (!filters.excludedBomCodes.includes(projectBuiltItem.builtItem.bomCode)) {
                projectBuiltItem.builtItem.bomComponents.forEach((bomComponent) => {
                    rows.push({
                        type: RowType.BomComponent,
                        bomComponent,
                        bom: projectBuiltItem.builtItem,
                    })
                })
            }

            rows.push({
                type: RowType.Space,
            })
        })

        projectStockItems.forEach((projectStockItem) => {
            rows.push({
                type: RowType.StockItem,
                stockItem: projectStockItem.stockItem,
            })
        })

        return rows
    }, [callOffs, projectBuiltItems, projectStockItems, filters])

    const getItem = (row: TableBodyRow) => {
        if (isSpaceRow(row)) return null

        if (isStockItemRow(row)) {
            return row.stockItem.code
        }

        if (isBomRow(row)) {
            return row.bom.bomCode
        }

        if (isBomComponentRow(row)) {
            return row.bomComponent.product.code
        }
    }

    const getDescription = (row: TableBodyRow) => {
        if (isSpaceRow(row)) return null

        if (isStockItemRow(row)) {
            return row.stockItem.description
        }

        if (isBomRow(row)) {
            return row.bom.product.description
        }

        if (isBomComponentRow(row)) {
            return row.bomComponent.product.description
        }
    }

    const calculateAveragePrice = (product: Bom | Product): string => {
        if ('bomCode' in product) {
            const sopBuiltItemOrderlines = salesOrders.flatMap((salesOrder) =>
                salesOrder.builtItemOrderlines.filter(
                    (sopOrderline) => sopOrderline.builtItem.bomCode === product.bomCode
                )
            )
            const totalPrice = sopBuiltItemOrderlines.reduce((acc, sopBuiltItemOrderline) => {
                return acc + sopBuiltItemOrderline.sellingUnitPrice * sopBuiltItemOrderline.amount
            }, 0)

            const totalAmount = sopBuiltItemOrderlines.reduce((acc, sopBuiltItemOrderline) => {
                return acc + sopBuiltItemOrderline.amount
            }, 0)

            return totalPrice && totalAmount
                ? formatNumberToUkString(totalPrice / totalAmount).toString()
                : '0'
        }
        const sopStockItemOrderlines = salesOrders.flatMap((salesOrder) =>
            salesOrder.stockItemOrderlines.filter(
                (sopOrderline) => sopOrderline.product.id === product.id
            )
        )
        // return the average price on the sopStockItemOrderlines
        const totalPrice = sopStockItemOrderlines.reduce((acc, sopStockItemOrderline) => {
            return acc + sopStockItemOrderline.sellingUnitPrice * sopStockItemOrderline.amount
        }, 0)
        const totalAmount = sopStockItemOrderlines.reduce((acc, sopStockItemOrderline) => {
            return acc + sopStockItemOrderline.amount
        }, 0)

        return totalPrice && totalAmount
            ? formatNumberToUkString(totalPrice / totalAmount).toString()
            : '0'
    }

    const calculateTotalPrice = (product: Bom | Product): string => {
        if ('bomCode' in product) {
            const sopBuiltItemOrderlines = salesOrders.flatMap((salesOrder) =>
                salesOrder.builtItemOrderlines.filter(
                    (sopOrderline) => sopOrderline.builtItem.bomCode === product.bomCode
                )
            )
            const totalPrice = sopBuiltItemOrderlines.reduce((acc, sopBuiltItemOrderline) => {
                return acc + sopBuiltItemOrderline.sellingUnitPrice * sopBuiltItemOrderline.amount
            }, 0)

            return formatNumberToUkString(totalPrice).toString()
        }
        const sopStockItemOrderlines = salesOrders.flatMap((salesOrder) =>
            salesOrder.stockItemOrderlines.filter(
                (sopOrderline) => sopOrderline.product.id === product.id
            )
        )
        // return the average price on the sopStockItemOrderlines
        const totalPrice = sopStockItemOrderlines.reduce((acc, sopStockItemOrderline) => {
            return acc + sopStockItemOrderline.sellingUnitPrice * sopStockItemOrderline.amount
        }, 0)
        return formatNumberToUkString(totalPrice).toString()
    }

    const getPriceString = (row: TableBodyRow) => {
        if (isStockItemRow(row) && filters.priceCalculation === PriceCalculation.Each) {
            return calculateAveragePrice(row.stockItem)
        }
        if (isStockItemRow(row) && filters.priceCalculation === PriceCalculation.Total) {
            return calculateTotalPrice(row.stockItem)
        }
        if (isBomRow(row) && filters.priceCalculation === PriceCalculation.Each) {
            return calculateAveragePrice(row.bom)
        }
        if (isBomRow(row) && filters.priceCalculation === PriceCalculation.Total) {
            return calculateTotalPrice(row.bom)
        }
        return null
    }

    const getQuantityOrdered = (
        row: TableBodyRow
    ): { onCallOffs: number; onSop: number; onProject: number } | null => {
        if (isSpaceRow(row)) return null

        if (isStockItemRow(row)) {
            const projectStockItem = projectStockItems.find(
                (projectStockItem) => projectStockItem.stockItem.id === row.stockItem.id
            )

            if (!projectStockItem || !projectStockItem.used || !projectStockItem.amount) {
                return null
            }

            // find the amount of the product on all call offs
            const onCallOffs = filteredCallOffs.reduce((acc, callOff) => {
                const callOffStockItem = callOff.stockItemCallOffs.find(
                    (callOffStockItem) =>
                        callOffStockItem.sopStockItemOrderline.productId ===
                        projectStockItem.stockItemId
                )
                if (!callOffStockItem) return acc
                return acc + callOffStockItem.amount
            }, 0)

            return { onCallOffs, onSop: projectStockItem.used, onProject: projectStockItem.amount }
        }

        if (isBomRow(row)) {
            const projectBuiltItem = projectBuiltItems.find(
                (projectBuiltItem) => projectBuiltItem.builtItem.id === row.bom.id
            )

            if (
                !projectBuiltItem ||
                !projectBuiltItem.used ||
                !projectBuiltItem.amount ||
                !projectBuiltItem.builtItemId
            ) {
                return null
            }
            // find the amount of the bom on all call offs
            const onCallOffs = filteredCallOffs.reduce((acc, callOff) => {
                const callOffBuiltItem = callOff.builtItemCallOffs.find((callOffBuiltItem) => {
                    if (!callOffBuiltItem?.sopBuiltItemOrderline?.builtItemId) {
                        if (!callOffBuiltItem?.sopBuiltItemOrderline?.builtItemId) {
                            toastFailure(
                                `Call off ${callOff.id} has a built item: ${
                                    callOffBuiltItem?.sopBuiltItemOrderline?.code ?? 'Unknown'
                                }, with no reference to a CTR buitl item.`
                            )
                            return false
                        }
                        return false
                    }
                    return (
                        callOffBuiltItem?.sopBuiltItemOrderline?.builtItemId ===
                        projectBuiltItem.builtItemId
                    )
                })
                if (!callOffBuiltItem) return acc
                return acc + callOffBuiltItem.amount
            }, 0)

            return { onCallOffs, onSop: projectBuiltItem.used, onProject: projectBuiltItem.amount }
        }

        if (isBomComponentRow(row)) {
            const projectBuiltItem = projectBuiltItems.find(
                (projectBuiltItem) => projectBuiltItem.builtItem.id === row.bom.id
            )
            const projectBuiltItemBomComponent = projectBuiltItem?.builtItem.bomComponents.find(
                (bomComponent) => bomComponent.id === row.bomComponent.id
            )

            if (
                !projectBuiltItem ||
                !projectBuiltItemBomComponent ||
                projectBuiltItem.used === undefined ||
                !projectBuiltItem.builtItemId
            ) {
                return null
            }

            if (!projectBuiltItemBomComponent.amount || !projectBuiltItem.amount) {
                return null
            }

            // find the amount of the bom component on all call offs
            const onCallOffs = filteredCallOffs.reduce((acc, callOff) => {
                const callOffBuiltItem = callOff.builtItemCallOffs.find((callOffBuiltItem) => {
                    if (!callOffBuiltItem?.sopBuiltItemOrderline?.builtItemId) {
                        toastFailure(
                            `Call off ${callOff.id} has a built item: ${
                                callOffBuiltItem?.sopBuiltItemOrderline?.code ?? 'Unknown'
                            }, with no reference to a CTR buitl item.`
                        )
                        return false
                    }
                    return (
                        callOffBuiltItem?.sopBuiltItemOrderline?.builtItemId ===
                        projectBuiltItem.builtItemId
                    )
                })
                if (!callOffBuiltItem) return acc
                const callOffBuiltItemBomComponent =
                    callOffBuiltItem.sopBuiltItemOrderline.builtItem.bomComponents.find(
                        (bomComponent) => bomComponent.id === row.bomComponent.id
                    )
                if (!callOffBuiltItemBomComponent) return acc
                return acc + callOffBuiltItemBomComponent.amount * callOffBuiltItem.amount
            }, 0)

            return {
                onCallOffs,
                onSop: projectBuiltItemBomComponent.amount * projectBuiltItem.used,
                onProject: projectBuiltItemBomComponent.amount * projectBuiltItem.amount,
            }
        }

        return null
    }

    // should make a function that returns the total value of all project built items and stock items for each call off
    const getCallOffsTotalPrices = (callOffs: CallOff[]): number[] => {
        return callOffs.map((callOff) => {
            const stockItemTotalPrice = callOff.stockItemCallOffs.reduce(
                (acc, stockItemCallOff) => {
                    return (
                        acc +
                        stockItemCallOff.sopStockItemOrderline?.sellingUnitPrice *
                            stockItemCallOff.amount
                    )
                },
                0
            )
            const builtItemTotalPrice = callOff.builtItemCallOffs.reduce(
                (acc, builtItemCallOff) => {
                    return (
                        acc +
                        builtItemCallOff.sopBuiltItemOrderline?.sellingUnitPrice *
                            builtItemCallOff.amount
                    )
                },
                0
            )
            return stockItemTotalPrice + builtItemTotalPrice
        })
    }

    const getCallOffTableRowCell = (row: TableBodyRow, callOff: CallOff) => {
        if (isSpaceRow(row)) return null

        if (isStockItemRow(row)) {
            const stockItemCallOff = callOff.stockItemCallOffs.find(
                (stockItemCallOff) =>
                    stockItemCallOff.sopStockItemOrderline?.product.id === row.stockItem.id
            )
            if (!stockItemCallOff) {
                return '-'
            }
            return stockItemCallOff?.amount ?? 'Error'
        }

        if (isBomRow(row)) {
            const builtItemCallOff = callOff.builtItemCallOffs.find(
                (builtItemCallOff) =>
                    builtItemCallOff.sopBuiltItemOrderline?.builtItem.id === row.bom.id
            )
            if (!builtItemCallOff) {
                return '-'
            }
            return builtItemCallOff?.amount ?? 'Error'
        }

        if (isBomComponentRow(row)) {
            const builtItemCallOff = callOff.builtItemCallOffs.find(
                (builtItemCallOff) =>
                    builtItemCallOff.sopBuiltItemOrderline?.builtItem.id === row.bom.id
            )
            const builtItemCallOffBomComponent =
                builtItemCallOff?.sopBuiltItemOrderline?.builtItem.bomComponents.find(
                    (bomComponent) => bomComponent.id === row.bomComponent.id
                )
            if (!builtItemCallOff) {
                return '-'
            }
            if (!builtItemCallOff || !builtItemCallOffBomComponent) return 'Error'

            return builtItemCallOff.amount * builtItemCallOffBomComponent.amount
        }

        return null
    }

    // combine the two tables into one, and export it as a csv
    const exportTableToCsv = () => {
        type Table = Array<Row>
        type Row = Array<Cell>
        type Cell = string | null
        const table: Table = []
        const headers: Row = []

        // add the headers
        headers.push('Item')
        headers.push('Description')
        headers.push('Called Off / On project')
        headers.push('Price')
        for (const callOff of filteredCallOffs) {
            headers.push(
                `${callOff.id} ${callOff.title ? ' - ' + callOff.title : ''} - ${
                    callOff.dispatchedDate
                        ? `Dispatched: ${moment(callOff.dispatchedDate).format('DD/MM/YY')}`
                        : `Scheduled: ${moment(callOff.deliveryDate).format('DD/MM/YY')}`
                }`
            )
        }

        table.push(headers)

        // add the rows
        rows.forEach((row) => {
            let item = getItem(row)
            let description = getDescription(row)
            const ordered = getQuantityOrdered(row)
            let price = getPriceString(row)
            item = item ? item.replace(/[\n\r]/g, ' ') : ' '
            description = description ? description.replace(/[\n\r]/g, ' ') : ' '
            price = price ? price.replace(/[\n\r]/g, ' ') : ' '
            const csvRow: Row = []
            csvRow.push(item)
            csvRow.push(description)
            if (ordered) {
                csvRow.push(
                    `\`${ordered?.onCallOffs} / ${ordered?.onSop} / ${ordered?.onProject}\``
                )
            } else {
                csvRow.push(' ')
            }
            // Handle null or undefined price
            csvRow.push(price)
            filteredCallOffs.forEach((callOff) => {
                let callOffCell = getCallOffTableRowCell(row, callOff)
                // Check if callOffCell is a string, if so, sanitize it
                if (typeof callOffCell === 'string') {
                    callOffCell = callOffCell.replace(/[\n\r]/g, ' ')
                }
                csvRow.push(callOffCell?.toString() ?? ' ')
            })
            table.push(csvRow)
        })

        let csv = ''

        table.forEach((row, rowIndex) => {
            row.forEach((cell, cellIndex) => {
                // Check if cell is not a space
                if (cell !== ' ' && cell !== null) {
                    // Escape cell content
                    const sanitizedCell = cell.replace(/"/g, '""').replace(/[\n\r]/g, ' ')
                    csv += `"${sanitizedCell}"`
                } else {
                    csv += ''
                }
                // Only add a comma if it's not the last cell in the row
                if (cellIndex < row.length - 1) {
                    csv += ','
                }
            })
            // Only add a newline if it's not the last row in the table
            if (rowIndex < table.length - 1) {
                csv += '\n'
            }
        })

        const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
        const url = URL.createObjectURL(csvData)
        const link = document.createElement('a')
        link.setAttribute('href', url)
        link.setAttribute(
            'download',
            `${project?.name ?? 'unknown_project'}_${moment().format('DD/MM/YY')}`
        )
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <CallOffFilterBar />
                <Button onClick={exportTableToCsv}>Export</Button>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    overflowX: 'auto',
                    maxWidth: '100%',
                    justifyContent: 'flex-start',
                    boxSizing: 'border-box',
                }}
            >
                <div>
                    <Table>
                        <thead>
                            <Tr>
                                <Th rowSpan={3}>
                                    <p style={{ color: TextColors.DarkBlue }}>Item</p>
                                </Th>
                                <Th style={{ maxWidth: '200px' }} rowSpan={3}>
                                    <p style={{ color: TextColors.DarkBlue }}>Description</p>
                                </Th>
                                <Th rowSpan={2} style={{ minWidth: '140px', maxWidth: '200px' }}>
                                    <p style={{ color: TextColors.DarkBlue }}>
                                        Called Off / on SOP / on Project
                                    </p>
                                </Th>

                                <Th style={{ maxWidth: '200px' }} rowSpan={3}>
                                    <p style={{ color: TextColors.DarkBlue }}>Price</p>
                                </Th>
                            </Tr>
                        </thead>
                        <tbody>
                            {rows.map((row, index) => {
                                return (
                                    <Tr key={index}>
                                        <Td style={{ minWidth: '220px' }}>
                                            <p
                                                style={{
                                                    color: TextColors.DarkBlue,
                                                    fontWeight: 800,
                                                    marginBottom: '0',
                                                }}
                                            >
                                                {getItem(row)}
                                            </p>
                                        </Td>
                                        <Td style={{ minWidth: '200px', maxWidth: '250px' }}>
                                            <TdClamp>
                                                <P>{getDescription(row)}</P>
                                            </TdClamp>
                                        </Td>
                                        <Td>
                                            <p
                                                style={{
                                                    color:
                                                        getQuantityOrdered(row)?.onSop ===
                                                        getQuantityOrdered(row)?.onProject
                                                            ? TextColors.DarkBlue
                                                            : TextColors.Red,
                                                    marginBottom: '0',
                                                }}
                                            >
                                                {getQuantityOrdered(row)
                                                    ? `
                                                    ${getQuantityOrdered(row)?.onCallOffs} /
                                                    ${getQuantityOrdered(row)?.onSop} / ${
                                                          getQuantityOrdered(row)?.onProject
                                                      }`
                                                    : ''}
                                            </p>
                                        </Td>

                                        <Td>
                                            <P>
                                                {getPriceString(row)
                                                    ? `£${getPriceString(row)}`
                                                    : ''}
                                            </P>
                                        </Td>
                                    </Tr>
                                )
                            })}
                            <Tr>
                                <Td></Td>
                                <Td>
                                    <P>Call Off Total Price:</P>
                                </Td>
                                <Td></Td>
                                <Td></Td>
                            </Tr>
                        </tbody>
                    </Table>
                </div>
                <div style={{ overflowX: 'auto', boxSizing: 'border-box' }}>
                    <Table>
                        <thead>
                            <Tr>
                                {filteredCallOffs.map((callOff, callOffIndex) => (
                                    <Th
                                        style={{
                                            backgroundColor:
                                                callOffIndex % 2 === 0
                                                    ? CallOffCellColors.Even
                                                    : CallOffCellColors.Uneven,
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            paddingBottom: '2px',
                                        }}
                                        key={callOff.id}
                                    >
                                        <p
                                            style={{
                                                marginBottom: '0',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                color: callOff.dispatchedDate
                                                    ? TextColors.DarkBlue
                                                    : TextColors.Red,
                                            }}
                                        >
                                            {callOff?.salesOrder?.documentNo
                                                ? `${callOff.salesOrder.documentNo}`
                                                : 'No sales order'}
                                        </p>
                                        <p
                                            style={{
                                                marginBottom: '0',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                maxWidth: 150,
                                                color: callOff.dispatchedDate
                                                    ? TextColors.DarkBlue
                                                    : TextColors.Red,
                                            }}
                                        >
                                            {`${callOff.id} ${
                                                callOff.title ? '- ' + callOff.title : ''
                                            }`}
                                        </p>
                                        <p
                                            style={{
                                                marginBottom: '0',
                                                color: callOff.dispatchedDate
                                                    ? TextColors.DarkBlue
                                                    : TextColors.Red,
                                            }}
                                        >
                                            {callOff.dispatchedDate
                                                ? `Dispatched: ${moment(
                                                      callOff.dispatchedDate
                                                  ).format('DD/MM/YY')}`
                                                : `Scheduled: ${moment(callOff.deliveryDate).format(
                                                      'DD/MM/YY'
                                                  )}`}
                                        </p>
                                    </Th>
                                ))}
                            </Tr>
                        </thead>
                        <tbody>
                            {rows.map((row, index) => {
                                return (
                                    <Tr key={index}>
                                        {filteredCallOffs.map((callOff, callOffIndex) => (
                                            <Td
                                                style={{
                                                    backgroundColor:
                                                        callOffIndex % 2 === 0
                                                            ? CallOffCellColors.Even
                                                            : CallOffCellColors.Uneven,
                                                    color: callOff.dispatchedDate
                                                        ? TextColors.DarkBlue
                                                        : TextColors.Red,
                                                }}
                                                key={callOff.id}
                                            >
                                                {getCallOffTableRowCell(row, callOff)}
                                            </Td>
                                        ))}
                                    </Tr>
                                )
                            })}
                            <Tr>
                                {getCallOffsTotalPrices(filteredCallOffs).map((price, i) => (
                                    <Td
                                        key={i}
                                        style={{
                                            backgroundColor:
                                                i % 2 === 0
                                                    ? CallOffCellColors.Even
                                                    : CallOffCellColors.Uneven,
                                            color: TextColors.DarkBlue,
                                        }}
                                    >
                                        <P>{'£' + formatNumberToUkString(price)}</P>
                                    </Td>
                                ))}
                            </Tr>
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    )
}
