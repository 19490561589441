import { Typography, Box } from '@mui/material'
import { useSelector } from 'react-redux'
import {
    getWarehouseMapSpotKey,
    selectLogisticsWarehouseMapSpots,
} from '../../store/warehouse-map-slice'
import { WarehouseMapColors } from '../../theme/colors'
import Spot from './Spot'

export const LogisticsFloor = () => {
    const spots = useSelector(selectLogisticsWarehouseMapSpots)
    const getSpot = (x: number, y: number) => {
        return spots[getWarehouseMapSpotKey(x, y)]
    }

    return (
        <Box>
            <Box
                style={{
                    display: 'flex',
                    width: '100%',
                }}
            >
                <Box
                    style={{
                        backgroundColor: WarehouseMapColors.green,
                        minWidth: '97.236px',
                        width: 'calc(11.11% - 10px)',
                        height: '20px',
                        margin: '0px 5px',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        textAlign: 'center',
                    }}
                >
                    <Typography style={{ fontSize: '10px', color: 'white', fontWeight: 600 }}>
                        Goods Out / Completed
                    </Typography>
                </Box>

                <Box
                    style={{
                        backgroundColor: WarehouseMapColors.yellowTitleMonumentumLogisticsFloor,
                        minWidth: '188px',
                        width: 'calc(11.11% - 10px)',
                        height: '20px',
                        margin: '0px 5px',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        textAlign: 'center',
                    }}
                >
                    <Typography style={{ fontSize: '10px', color: 'white', fontWeight: 600 }}>
                        Delivery Preparations
                    </Typography>
                </Box>
                <Box
                    style={{
                        backgroundColor: WarehouseMapColors.amberTitleMonumentumLogisticsFloor,
                        minWidth: '292px',
                        width: 'calc(22.22% - 10px)',
                        height: '20px',
                        margin: '0px 5px',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        textAlign: 'center',
                    }}
                >
                    <Typography style={{ fontSize: '10px', color: 'white', fontWeight: 600 }}>
                        Transfer to Assembly
                    </Typography>
                </Box>
                <Box
                    style={{
                        backgroundColor: WarehouseMapColors.longStayColor,
                        minWidth: '97.236px',
                        width: 'calc(11.11% - 10px)',
                        height: '20px',
                        margin: '0px 5px',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        textAlign: 'center',
                    }}
                >
                    <Typography style={{ fontSize: '10px', color: 'white', fontWeight: 600 }}>
                        Long Stay
                    </Typography>
                </Box>
                <Box
                    style={{
                        backgroundColor: WarehouseMapColors.greyTitleMonumentumLogisticsFloor,
                        minWidth: '590px',
                        width: 'calc(44.44% - 10px)',
                        height: '20px',
                        margin: '0px 5px',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        textAlign: 'center',
                    }}
                >
                    <Typography style={{ fontSize: '10px', color: 'white', fontWeight: 600 }}>
                        Re-saleable Stock/General Stock
                    </Typography>
                </Box>
            </Box>
            <Box
                style={{
                    display: 'flex',
                    width: 'calc(100% - 2 * 27px)',
                }}
            >
                {Array.from(Array(9)).map((_, i) => (
                    <Box style={{ display: 'flex', margin: '0px 3px', width: '100%' }} key={i}>
                        {Array.from(Array(4)).map((_, j) => {
                            const x = i * 4 + j
                            return (
                                <Box key={j} style={{ width: '100%' }}>
                                    <Box
                                        style={{
                                            fontSize: '12px',
                                            textAlign: 'center',
                                            width: '100%',
                                        }}
                                    >
                                        {x + 1}
                                    </Box>
                                    {Array.from(Array(12)).map((_, y) => {
                                        const spot = getSpot(x, y)
                                        return <Spot spot={spot} key={y} />
                                    })}
                                </Box>
                            )
                        })}
                    </Box>
                ))}
            </Box>
            <Box
                style={{
                    marginTop: '10px',
                    display: 'flex',
                    width: 'calc(100% - 2 * 27px)',
                }}
            >
                <Box
                    style={{
                        backgroundColor: 'white',
                        minWidth: '890px',
                        marginLeft: 'max(304px, 22.22% + 4px)',
                        width: 'calc(66.66% - 10px)',
                        height: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        textAlign: 'center',
                    }}
                >
                    <Typography style={{ fontSize: '10px', fontWeight: 600 }}>
                        Overflow / Buffer
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}
