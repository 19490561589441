import SAV_landing from '../images/SAV_Frame_Landing.svg'
import CTR_landing from '../images/CTR-LOGO.svg'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { mainSAVTheme } from '../theme/Theme'
import { Link } from 'react-router-dom'
import LogoutIcon from '@mui/icons-material/Logout'
import { authenticatedUserView, logout } from '../store/authentication-slice'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { NavigationOption, navigationOptions } from './dashboard/navigationOptions'
import React from 'react'

export default function Landing() {
    const dispatch = useAppDispatch()
    const user = useAppSelector(authenticatedUserView)

    function renderListItem(
        option: NavigationOption,
        index: number
    ): JSX.Element | JSX.Element[] | null {
        if (user && option.permissions && !option.permissions?.includes(user.role)) {
            return null
        } else if (option.options && option.options.length > 0) {
            return (
                <React.Fragment
                    key={index + option.options.map((option) => option.text).toString()}
                >
                    {' '}
                    {option.options.map((option, index) => renderListItem(option, index))}{' '}
                </React.Fragment>
            )
        } else {
            return (
                <Button
                    key={index}
                    component={Link}
                    to={option.link}
                    sx={{
                        textTransform: 'none',
                        fontSize: '20px',
                        '&:first-child': {
                            marginTop: '0px',
                        },
                        marginTop: '20px',
                        color: `${mainSAVTheme.palette.secondary.main}`,
                        '&:hover': {
                            backgroundColor: `${mainSAVTheme.palette.primary.dark}`,
                            color: `${mainSAVTheme.palette.secondary.main}`,
                        },
                    }}
                >
                    <div style={{ marginRight: '10px' }}>{option.icon}</div>
                    {option.text}
                </Button>
            )
        }
    }

    return (
        <Box
            className="Landing"
            sx={{
                backgroundColor: `${mainSAVTheme.palette.primary.main}`,
                height: '100%',
                '@media (max-height: 900px)': {
                    minHeight: 'unset',
                },
                '@media (max-height: 1100px)': {
                    minHeight: '115%',
                },
                minHeight: '100%',
            }}
        >
            <Grid
                container
                component="main"
                sx={{
                    padding: '90px',
                    height: '100%',
                }}
            >
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        overflow: 'hidden',
                    }}
                >
                    <img
                        src={SAV_landing}
                        alt="SAV_landing_img"
                        style={{
                            flexShrink: 0,
                            minWidth: '100%',
                            minHeight: '100%',
                            maxHeight: '80vh',
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={8} md={5} style={{ position: 'relative' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginLeft: '30%',
                        }}
                    >
                        <Grid item xs={10} container direction="column" alignItems="flex-start">
                            {navigationOptions.map((option, index) =>
                                renderListItem(option, index)
                            )}
                            <Button
                                onClick={() => dispatch(logout())}
                                sx={{
                                    textTransform: 'none',
                                    fontSize: '20px',
                                    marginTop: '20px',
                                    color: `${mainSAVTheme.palette.secondary.main}`,
                                    '&:hover': {
                                        backgroundColor: `${mainSAVTheme.palette.primary.dark}`,
                                        color: `${mainSAVTheme.palette.secondary.main}`,
                                    },
                                }}
                            >
                                <LogoutIcon style={{ marginRight: '10px' }} />
                                Logout
                            </Button>
                        </Grid>
                    </Box>
                    <img
                        src={CTR_landing}
                        alt="SAV_logo"
                        style={{ position: 'absolute', right: 0, bottom: 0 }}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}
