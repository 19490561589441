/* eslint-disable @typescript-eslint/no-explicit-any */
import { Route, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import Login from '../components/Login'
import Landing from '../components/Landing'
import Dashboard from '../components/dashboard/Dashboard'
import { getUser, isAuthenticatedView, authenticatedUserView } from '../store/authentication-slice'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { useEffect } from 'react'
import ResetPassword from '../components/authentication/reset-password/ResetPassword'
import VerifyProfile from '../components/authentication/verify-profile/VerifyProfile'
import 'react-toastify/dist/ReactToastify.css'
import SageAuth from '../components/SageAuth'
import { Urls } from '../util/urls'
import { ConditionalRoute } from '../components/ConditionalRoute'
import MobileDetect from 'mobile-detect'
export default function App() {
    const dispatch = useAppDispatch()
    const isAuthenticated = useAppSelector(isAuthenticatedView)
    const user = useAppSelector(authenticatedUserView)

    useEffect(() => {
        dispatch(getUser())
    }, [isAuthenticated])

    useEffect(() => {
        const userAgent = navigator.userAgent
        const md = new MobileDetect(userAgent)
        const tablet = md.tablet()
    }, [])

    return (
        <div className="App" style={{ height: '100%' }}>
            <ToastContainer />
            <Switch>
                <Route path={Urls.SageAuth} render={() => <SageAuth />} />
                <ConditionalRoute
                    condition={!isAuthenticated}
                    path={Urls.Login}
                    render={() => <Login />}
                />
                <ConditionalRoute
                    condition={!isAuthenticated}
                    path={Urls.ResetPassword}
                    render={(props: any) => <ResetPassword {...props} />}
                />
                <ConditionalRoute
                    condition={!isAuthenticated}
                    path={Urls.VerifyProfile}
                    render={(props: any) => <VerifyProfile {...props} />}
                />
                <ConditionalRoute
                    condition={isAuthenticated}
                    path={Urls.Dashboard}
                    render={() => <Dashboard />}
                />
                <ConditionalRoute
                    condition={isAuthenticated}
                    render={() => <Landing />}
                    elseRender={() => <Login />}
                    exact
                    path={Urls.Landing}
                />
            </Switch>
            <ToastContainer />
        </div>
    )
}
