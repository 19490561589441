import { useEffect, useState, MouseEvent } from 'react'
import { UserRole, User, PalletEventType, PalletEvent } from '../../../../models/models'
import { useAppSelector } from '../../../../store/hooks'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    TablePagination,
} from '@mui/material'
import { getUsersRequest } from '../../../../api/users'
import { snakeCaseToTitleCase } from '../../../../util/util'
import { getPalletEvents, PalletEventsRelations } from '../../../../api/warehouse-map'
import moment from 'moment'
import { toastFailure } from '../../../../util/toast'
import DatePicker from '../../../ui/calendar/DatePicker'

export const EventLogsList = () => {
    const rowsPerPageOptions = [5, 10, 20]
    type RowsPerPageOptions = typeof rowsPerPageOptions[number]

    const [page, setPage] = useState<number>(0)
    const [rowsPerPage, setRowsPerPage] = useState<RowsPerPageOptions>(rowsPerPageOptions[0])
    const [totalCount, setTotalCount] = useState<number>(0)

    const authenticatedUser = useAppSelector((state) => state.authentication.user)
    const [projectManagers, setProjectManagers] = useState<User[]>([])
    const [projectManagerFilter, setProjectManagerFilter] = useState<string>(
        authenticatedUser!.role === UserRole.ProjectManager ? String(authenticatedUser!.id) : ''
    )
    const [startDate, setStartDate] = useState<Date | null>(null)
    const [endDate, setEndDate] = useState<Date | null>(null)
    const [eventType, setEventType] = useState<string>('')
    const [eventLogItems, setEventLogItems] = useState<PalletEvent[]>([])

    useEffect(() => {
        async function fetchProjectManagers() {
            const response = await getUsersRequest({
                roles: [UserRole.ProjectManager, UserRole.Scheduler],
            })
            if (response.successful) {
                setProjectManagers(response.data.entities)
            }
        }

        fetchProjectManagers()
    }, [])

    useEffect(() => {
        async function fetchEventsLog() {
            const response = await getPalletEvents({
                relations: [
                    PalletEventsRelations.Assembly,
                    PalletEventsRelations.CallOff,
                    PalletEventsRelations.Pallet,
                    PalletEventsRelations.Project,
                    PalletEventsRelations.Spots,
                    PalletEventsRelations.User,
                ],
                startDate: startDate ? startDate : undefined,
                endDate: endDate ? endDate : undefined,
                userId: Number(projectManagerFilter),
                withCount: true,
                skip: page * rowsPerPage,
                limit: rowsPerPage,
            })
            if (response.successful) {
                setEventLogItems(response.data.events)
                setTotalCount(response.data.count)
            } else {
                toastFailure('Could not retrieve event logs.')
            }
        }

        fetchEventsLog()
    }, [rowsPerPage, page, startDate, endDate, projectManagerFilter])

    const onRowPerPageChange = (rowsPerPage: RowsPerPageOptions) => {
        setRowsPerPage(rowsPerPage)
    }

    const pageChange = (
        event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent> | null,
        page: number
    ) => {
        const newLastPage = Math.ceil(totalCount / rowsPerPage) - 1
        setPage(Math.max(Math.min(newLastPage, page), 0))
    }

    const handleChangeDates = (dates: [moment.Moment | null, moment.Moment | null] | null) => {
        if (dates !== null) {
            if (dates[0] !== null) {
                setStartDate(dates[0].toDate())
            } else {
                setStartDate(null)
            }

            if (dates[1] !== null) {
                setEndDate(dates[1].toDate())
            } else {
                setEndDate(null)
            }
        }
    }

    return (
        <>
            <Paper
                elevation={0}
                square
                sx={{ padding: '23px 24px 23px 24px', background: 'transparent' }}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    columns={{ xs: 4, md: 8 }}
                    spacing={4}
                >
                    <Grid item>
                        <DatePicker.RangePicker onCalendarChange={handleChangeDates} />
                    </Grid>
                    <Grid item></Grid>
                    <Grid item>
                        <FormControl sx={{ minWidth: 160 }} size="small">
                            <InputLabel>Event Type</InputLabel>
                            <Select
                                label="Event Type"
                                value={eventType || ''}
                                onChange={(event: SelectChangeEvent): void => {
                                    setEventType(String(event.target.value))
                                }}
                                autoWidth
                                style={{ borderRadius: '10px 0px 0px 10px' }}
                            >
                                <MenuItem value={''}>
                                    <em>Any</em>
                                </MenuItem>
                                {Object.values(PalletEventType).map((type) => (
                                    <MenuItem key={type} value={type}>
                                        {snakeCaseToTitleCase(type.toString())}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl sx={{ minWidth: 160 }} size="small">
                            <InputLabel>Project manager</InputLabel>
                            <Select
                                label="Project manager"
                                value={projectManagerFilter || undefined}
                                onChange={(event: SelectChangeEvent): void => {
                                    setProjectManagerFilter(String(event.target.value))
                                }}
                                autoWidth
                                style={{ borderRadius: '0px 10px 10px 0px' }}
                            >
                                <MenuItem value={''}>
                                    <em>Any</em>
                                </MenuItem>
                                {projectManagers.map((manager) => (
                                    <MenuItem key={manager.id} value={manager.id}>
                                        {manager.firstName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Paper>

            <TableContainer
                component={Paper}
                style={{ margin: '24px', width: 'calc(100% - 2 * 24px)' }}
            >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>From Spot</TableCell>
                            <TableCell>To Spot</TableCell>
                            <TableCell>Author</TableCell>
                            <TableCell>Created At</TableCell>
                            <TableCell>Event Type</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {[...eventLogItems]
                            ?.sort(
                                (a, b) =>
                                    (b.timeCreatedAt ? new Date(b.timeCreatedAt).getTime() : 0) -
                                    (a.timeCreatedAt ? new Date(a.timeCreatedAt).getTime() : 0)
                            )
                            ?.map((event: PalletEvent) => (
                                <TableRow key={event.id} hover sx={{ cursor: 'pointer' }}>
                                    <TableCell component="th" scope="row">
                                        {event.fromSpot
                                            ? `(X:${event.fromSpot?.x}, Y:${event.fromSpot?.y})`
                                            : '-'}
                                    </TableCell>
                                    <TableCell>
                                        {event.toSpot
                                            ? `(X:${event.toSpot?.x}, Y:${event.toSpot?.y})`
                                            : '-'}
                                    </TableCell>
                                    <TableCell>
                                        {event.author?.firstName} {event.author?.lastName}
                                    </TableCell>
                                    <TableCell>
                                        {moment(event.timeCreatedAt).format('MMMM Do YYYY, hh:mm')}
                                    </TableCell>
                                    <TableCell>{event.type}</TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                count={totalCount}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                onPageChange={(
                                    event: MouseEvent<
                                        HTMLButtonElement,
                                        globalThis.MouseEvent
                                    > | null,
                                    page: number
                                ) => pageChange(event, page)}
                                rowsPerPageOptions={rowsPerPageOptions}
                                onRowsPerPageChange={(e) => {
                                    onRowPerPageChange(Number(e.target.value))
                                }}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </>
    )
}
