export const Colors = {
    white: '#262626',
    blue_add8e6: '#ADD8E6',
    black_262626: '#262626',
    grey_9d9d9d: '#9D9D9D',
    grey_d9d9d9: '#D9D9D9',
    grey_bfbfbf: '#BFBFBF',
    grey_d8d9dc: '#d8d9dc',
    dark_grey_d3d3d3: '#D3D3D3',
    light_grey_f5f5f5: '#F5F5F5',
    light_grey_ebebed: '#ebebed',
    light_grey_f0f2f5: '#F0F2F5',
    white_ffffff: '#ffffff',
    purple_3500a0: '#3500A0',
    light_purple_5d33b3: '#5D33B3',
    dark_purple_250070: '#250070',
    red_d32f2f: '#d32f2f',
    forest: '#2D462D',
    darkGreen: '#aae8a9',
    light_green_E4EEDC: '#E4EEDC',
    green_A0CD62: '#A0CD62',
    light_yellow_FBE5A2: '#FBE5A2',
    dark_yellow_CCAA00: '#CCAA00',
    yellow_E8C902: '#e8c902',
    yellow_FFDC00: '#FFDC00',
    dark_yellow_F9D978: '#F9D978',
    light_brown_B8903A: '#B8903A',
    brown_7C6125: '#7C6125',
    blue_4CAFEA: '#4CAFEA',
    red_F3CFB7: '#F3CFB7',
    blue_3876FF: '#3876FF',
    blue_B4C6ED: '#B4C6ED',
    red_FF6847: '#FF6847',
    red_B03A2E: '#B03A2E',
    red_EC7063: '#EC7063',
}

export const TextColors = {
    lighterGrey: Colors.grey_bfbfbf,
    lightGrey: Colors.grey_9d9d9d,
    white: Colors.white_ffffff,
    black: Colors.black_262626,
    purple: Colors.purple_3500a0,
    lightPurple: Colors.light_purple_5d33b3,
    darkPurple: Colors.dark_purple_250070,
    forest: Colors.forest,
}

export const BackgroundColors = {
    white: Colors.white_ffffff,
    purple: Colors.purple_3500a0,
    lightPurple: Colors.light_purple_5d33b3,
    lightGrey: Colors.light_grey_f5f5f5,
    lighterGrey: Colors.light_grey_f0f2f5,
    darkGrey: Colors.dark_grey_d3d3d3,
}

export const BorderColors = {
    lightGrey: Colors.grey_9d9d9d,
    lighterGrey: Colors.grey_d9d9d9,
    black: Colors.black_262626,
    white: Colors.white_ffffff,
    darkGrey: Colors.dark_grey_d3d3d3,
    purple: Colors.purple_3500a0,
    red: Colors.red_d32f2f,
}

export const WarehouseMapColors = {
    white: Colors.white_ffffff,
    deleteButtonColor: Colors.red_EC7063,
    deleteButtonOnHoverColor: Colors.red_B03A2E,
    longStayColor: Colors.blue_3876FF,
    blue: Colors.blue_B4C6ED,
    splitButtonColor: Colors.grey_d9d9d9,
    splitButtonOnHoverColor: Colors.dark_grey_d3d3d3,
    //Monumentum
    greyOffMapPallet: Colors.grey_d8d9dc,
    grey: Colors.light_grey_ebebed,
    lightGreen: Colors.light_green_E4EEDC,
    green: Colors.green_A0CD62,
    //In Progress Floor 1 title
    yellowTitleMonumentumLogisticsFloor: Colors.yellow_E8C902,
    //Transfer Floor 1 title
    lightRed: Colors.red_F3CFB7,
    amberTitleMonumentumLogisticsFloor: Colors.red_FF6847,
    //Booked In title Floor 1 title
    lightGrey: Colors.grey_d8d9dc,
    greyTitleMonumentumLogisticsFloor: Colors.grey_9d9d9d,
    // Assembly Floor
    lightYellow: Colors.light_yellow_FBE5A2,
    yellow: Colors.yellow_FFDC00,
    darkYellow: Colors.dark_yellow_F9D978,
    lightBrown: Colors.light_brown_B8903A,
    brown: Colors.brown_7C6125,
    lightBlue: Colors.blue_4CAFEA,
    lighterBlue: Colors.blue_add8e6,
}
