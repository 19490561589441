import Paper from '@mui/material/Paper'
import SAV_Frame_Dashboard from '../../../images/SAV_Frame_Dashboard.svg'
import { useEffect } from 'react'
import { Box } from '@mui/material'
import { TabContext, TabPanel } from '@mui/lab'
import {
    fetchEntities,
    setActiveView,
    startCreating,
    startUpdating,
    View,
} from '../../../store/single-project-view-slice'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import ProjectInfo from './project-info/ProjectInfo'
import { ArrowLeftOutlined } from '@ant-design/icons'
import ProductInfo from './product-info/ProductInfo'
import CallOff from './call-off/CallOff'
import Contacts from './contacts/Contacts'
import CreateNewEntityModal from './CreateNewEntityModal'
import LoadingOverlay from './LoadingOverlay'
import PageHeader from '../../ui/PageHeader'
import ProjectHistory from './project-history/ProjectHistory'
import DocumentControl from './document-control/DocumentControl'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { FormTabList } from '../../ui/tabs/FormTabList'
import { FormTab } from '../../ui/tabs/FormTab'
import SalesOrders from '../sales-orders/SalesOrders'
import { Urls } from '../../../util/urls'
import { Project } from '../../../models/models'
import { styled } from '@mui/system'
import CTRLoader from '../../ui/loader/CTRloader'

interface ParamTypes {
    id: string
    tab?: string
}

const LoadingIndicator = () => {
    const Container = styled('div')`
        display: flex;
        height: 600px;
        align-items: center;
        justify-content: center;
    `

    return (
        <Container>
            <CTRLoader />
        </Container>
    )
}

export default function SingleProjectView() {
    const { id, tab } = useParams<ParamTypes>()

    let project: Project | undefined
    const location = useLocation<{ project: Project }>()

    if (location?.state?.project) {
        project = location.state.project
    }
    const history = useHistory()
    const dispatch = useAppDispatch()
    const activeView = useAppSelector((state) => state.singleProjectView.activeView)

    const projectBeingUpdated =
        useAppSelector((state) => state.singleProjectView.projectBeingUpdated) || project
    const projectMatchingOpportunityReference = useAppSelector(
        (state) => state.singleProjectView.projectMatchingOpportunityReference
    )

    const title = projectBeingUpdated?.name ?? 'New project'
    const hasId = id && id !== 'new'
    const isLoading = hasId && !projectBeingUpdated

    useEffect(() => {
        dispatch(fetchEntities())

        if (!projectBeingUpdated && !hasId) {
            dispatch(startCreating())
        } else if (hasId) {
            dispatch(startUpdating(Number(id)))
        }

        dispatch(setActiveView((tab as View) || View.ProjectInfo))
    }, [])

    if (!activeView || (!projectBeingUpdated && id !== 'new')) {
        return null
    }

    return (
        <Box sx={{ position: 'relative' }}>
            <PageHeader
                title={title}
                titleLeftIcon={
                    <ArrowLeftOutlined
                        style={{ fontSize: 20, display: 'block' }}
                        onClick={() => history.push(Urls.ProjectMaster)}
                    />
                }
                breadcrumbs={[
                    { link: Urls.Landing, name: 'Main Page' },
                    {
                        link: Urls.ProjectMaster,
                        name: 'Project Master',
                    },
                    {
                        name: title,
                    },
                ]}
            />
            <Box
                sx={{
                    padding: '23px 24px 23px 24px',
                }}
            >
                <TabContext value={activeView}>
                    <FormTabList
                        onChange={(event, newValue) => {
                            history.push(`/dashboard/project-master/${id}/${newValue}`)
                            dispatch(setActiveView(newValue))
                        }}
                    >
                        <FormTab label="Project Info" value={View.ProjectInfo} />
                        {(projectBeingUpdated || projectMatchingOpportunityReference) && (
                            <FormTab label="Document Control" value={View.DocumentControl} />
                        )}
                        {projectBeingUpdated && (
                            <FormTab label="Product info" value={View.ProductInfo} />
                        )}
                        {projectBeingUpdated && (
                            <FormTab label="Contact info" value={View.Contacts} />
                        )}
                        {projectBeingUpdated && (
                            <FormTab label="Sales Orders" value={View.SalesOrders} />
                        )}
                        {projectBeingUpdated && <FormTab label="Call off" value={View.CallOff} />}
                        {projectBeingUpdated && <FormTab label="History" value={View.History} />}
                    </FormTabList>
                    <Paper elevation={0} square>
                        {isLoading ? (
                            <LoadingIndicator />
                        ) : (
                            <>
                                <TabPanel value={View.ProjectInfo}>
                                    <ProjectInfo isNewProject={!projectBeingUpdated?.name} />
                                </TabPanel>
                                <TabPanel value={View.DocumentControl}>
                                    <DocumentControl />
                                </TabPanel>
                                <TabPanel value={View.ProductInfo}>
                                    <ProductInfo />
                                </TabPanel>
                                <TabPanel value={View.Contacts}>
                                    <Contacts />
                                </TabPanel>
                                <TabPanel value={View.SalesOrders}>
                                    <SalesOrders />
                                </TabPanel>
                                <TabPanel value={View.CallOff}>
                                    <CallOff />
                                </TabPanel>
                                <TabPanel value={View.History}>
                                    <ProjectHistory />
                                </TabPanel>
                            </>
                        )}
                    </Paper>
                </TabContext>
            </Box>

            <CreateNewEntityModal />
            <LoadingOverlay />

            <img
                src={SAV_Frame_Dashboard}
                alt="SAV_landing_img"
                style={{
                    position: 'absolute',
                    zIndex: -1,
                    bottom: '50px',
                    right: '93px',
                    flexShrink: 0,
                    minWidth: 'calc(100vw - 420px)',
                }}
            />
        </Box>
    )
}
