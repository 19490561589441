import { Box, styled } from '@mui/system'
import { Button, Checkbox, Input, Table } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import {
    deleteProjectBuiltItemRequest,
    getProjectBuiltItems,
} from '../../../../../../api/projects/built-items'
import { Project, ProjectBuiltItem } from '../../../../../../models/models'
import { useAppSelector } from '../../../../../../store/hooks'
import { ConfirmationModal } from '../../../../../ui/ConfirmationModal'
import { toastFailure, toastSuccess } from '../../../../../../util/toast'
import DeleteIcon from '@mui/icons-material/Delete'
import UpdateProjectBuiltItemModal from '../built-items/UpdateProjectBuiltItemModal'
import { InspectBomModal } from '../../../../../products/modals/InspectBomModal'
import { ColumnsType } from 'antd/lib/table'

const InputsContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
}))

enum ActiveModal {
    PlotNumbers = 'PlotNumbers',
    Inspect = 'Inspect',
    Update = 'Update',
    Delete = 'Delete',
    None = 'None',
}

export default function BuiltItems() {
    const [builtItems, setBuiltItems] = useState<ProjectBuiltItem[]>([])
    const [bomCode, setBomCode] = useState<string>('')
    const project: Project | null = useAppSelector(
        (state) => state.singleProjectView.projectBeingUpdated
    )
    const projectId = project!.id
    const [modalProjectBuiltItemId, setModalProjectBuiltItemId] = useState<number>()
    const rowsPerPageOptions = [10, 20, 50, 100]
    type RowsPerPageOptions = typeof rowsPerPageOptions[number] // 10 | 20 | 50 | 100
    const [page, setPage] = useState<number>(1)
    const [rowsPerPage, setRowsPerPage] = useState<RowsPerPageOptions>(10)
    const [amount, setAmount] = useState<number>(0)
    const [activeModal, setActiveModal] = useState<ActiveModal>(ActiveModal.None)
    const [showNotOnSalesOrders, setShowNotOnSalesOrders] = useState<boolean>(false)

    const filteredBuiltItems = useMemo(() => {
        return builtItems.filter((bi) => {
            if (showNotOnSalesOrders && typeof bi.used === 'number') {
                return bi.amount > bi.used
            } else {
                return true
            }
        })
    }, [builtItems, showNotOnSalesOrders])

    const columns = useMemo(() => {
        const columns: ColumnsType<ProjectBuiltItem> = [
            {
                title: 'Bom code',
                key: 'bomCode',
                render: (pb: ProjectBuiltItem) => pb.builtItem.bomCode,
            },
            {
                title: 'Name',
                key: 'name',
                render: (pb: ProjectBuiltItem) => pb.builtItem.product.name,
            },
            {
                title: 'Description',
                key: 'description',
                render: (pb: ProjectBuiltItem) => pb.builtItem.product.description,
            },
        ]

        if (showNotOnSalesOrders) {
            columns.push({
                title: 'Remainder for sales orders',
                dataIndex: 'amount',
                key: 'amount',
            })
        } else {
            columns.push(
                ...[
                    {
                        title: 'Quantity',
                        dataIndex: 'amount',
                        key: 'amount',
                    },
                    {
                        title: 'Amount on sales-orders',
                        dataIndex: 'used',
                        key: 'used',
                    },
                ]
            )
        }

        columns.push({
            title: 'Actions',
            key: 'Actions',
            render: (projectBuiltItem: ProjectBuiltItem) => (
                <Box sx={{ display: 'flex', gap: '10px' }}>
                    <Button
                        onClick={() => {
                            setActiveModal(ActiveModal.Inspect)
                            setModalProjectBuiltItemId(projectBuiltItem.builtItemId)
                        }}
                    >
                        Inspect
                    </Button>
                    <Button
                        onClick={() => {
                            setActiveModal(ActiveModal.Update)
                            setModalProjectBuiltItemId(projectBuiltItem.builtItemId)
                        }}
                    >
                        Update
                    </Button>
                    {projectBuiltItem.used === 0 && (
                        <Button
                            onClick={() => {
                                setActiveModal(ActiveModal.Delete)
                                setModalProjectBuiltItemId(projectBuiltItem.id)
                            }}
                        >
                            Delete
                        </Button>
                    )}
                </Box>
            ),
        })

        return columns
    }, [showNotOnSalesOrders])

    const deleteProjectBuiltItem = async (projectBuiltItemId: number) => {
        const response = await deleteProjectBuiltItemRequest(projectBuiltItemId)
        if (response.successful) {
            toastSuccess('Deleted Project built item')
            retrieveAndSetBuiltItems()
        } else {
            toastFailure(response.message)
        }
    }

    const pageChange = (page: number, pageSize: number) => {
        setPage(page)
        setRowsPerPage(pageSize)
    }

    const retrieveAndSetBuiltItems = async () => {
        const response = await getProjectBuiltItems(projectId, {
            builtItem: true,
            used: true,
            withCount: true,
            //mui tables pages are zero-indexed
            skip: showNotOnSalesOrders ? undefined : page * rowsPerPage - rowsPerPage,
            limit: showNotOnSalesOrders ? undefined : rowsPerPage,
            bomCode: bomCode,
        })
        if (response.successful) {
            setBuiltItems(response.data.projectBuiltItems)
            setAmount(response.data.count ?? 0)
        } else {
            toastFailure(response.message)
        }
    }

    useEffect(() => {
        retrieveAndSetBuiltItems()
    }, [bomCode, activeModal, page, rowsPerPage, showNotOnSalesOrders])

    return (
        <Box sx={{ marginTop: '20px' }}>
            <InputsContainer>
                <Input
                    prefix={'Code:'}
                    size="middle"
                    style={{
                        padding: 10,
                        borderRadius: '10px',
                        width: '25%',
                        margin: '10px',
                    }}
                    value={bomCode}
                    onChange={(e) => setBomCode(e.target.value)}
                />

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: '100%',
                        marginTop: '10px',
                    }}
                >
                    <Checkbox
                        style={{
                            margin: '10px',
                        }}
                        checked={showNotOnSalesOrders}
                        onChange={(e) => setShowNotOnSalesOrders(e.target.checked)}
                    >
                        Show not on sales-orders
                    </Checkbox>
                </div>
            </InputsContainer>
            <Table
                dataSource={filteredBuiltItems}
                columns={columns}
                size="small"
                pagination={{
                    defaultPageSize: 10,
                    current: page,
                    onChange: pageChange,
                    total: showNotOnSalesOrders ? filteredBuiltItems?.length : amount,
                }}
            />

            {modalProjectBuiltItemId && activeModal === ActiveModal.Inspect && (
                <InspectBomModal
                    bomId={modalProjectBuiltItemId}
                    isVisible={true}
                    onClose={() => {
                        setActiveModal(ActiveModal.None)
                        setModalProjectBuiltItemId(undefined)
                    }}
                />
            )}
            {modalProjectBuiltItemId && activeModal === ActiveModal.Delete && (
                <ConfirmationModal
                    title={'Delete project built item'}
                    text={`Are you sure you to delete the project built item with id: ${modalProjectBuiltItemId}?`}
                    confirmIcon={<DeleteIcon style={{ marginRight: '4px' }} />}
                    confirmText={'Delete'}
                    isOpen={!!modalProjectBuiltItemId && activeModal === ActiveModal.Delete}
                    onClose={() => {
                        setActiveModal(ActiveModal.None)
                        setModalProjectBuiltItemId(undefined)
                    }}
                    onConfirm={() => deleteProjectBuiltItem(modalProjectBuiltItemId)}
                />
            )}
            {modalProjectBuiltItemId && activeModal === ActiveModal.Update && (
                <UpdateProjectBuiltItemModal
                    projectBuiltItemId={modalProjectBuiltItemId}
                    projectId={projectId}
                    isOpen={true}
                    closeModal={() => {
                        setActiveModal(ActiveModal.None)
                        setModalProjectBuiltItemId(undefined)
                    }}
                />
            )}
        </Box>
    )
}
